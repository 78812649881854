<template>
    <!-- 전체 콘텐츠 영역 -->
    <div class="main-contents">
        <div class="mem_container resume">
            <div class="mem_info_contents">
                <div class="mem_contents_header">
                    <div class="title-txt">내 프로필 관리<br/><span>이력서 및 소속사 관리 / NCS 직무자가평가</span></div>
                </div>
                <div class="tab-type1">
                    <ul class="tab">
                        <li @click="pageMove('res')"><a><img src="/images/tec/prf/tab1.png" alt="이력서탭"/>이력서</a></li>
                        <li class="on">
                            <!-- <div v-if="asgRegYn == 'N'" class="alarm show"><img src="/images/tab_alarm.png" alt="미등록항목알람"><p>미평가 항목이 있습니다!</p></div> -->
                            <a><img src="/images/tec/prf/tab3.png" alt="소속사관리탭"/>소속사 관리</a>                            
                        </li>
                        <li @click="pageMove('ncs')"><a><img src="/images/tec/prf/tab2.png" alt="직무자가평가탭"/>NCS 직무 자가평가</a></li>
                    </ul>
                </div>

                <div class="contents-body tab">

                    <!-- 소속사 승인 요청 -->
                    <div class="corpMng-box company">
                        <div class="tit">원소속사</div>

                        <div class="corpInfo-box">
    
                            <div class="approv">
                                <img :src=" mberInfo.asgApprStatus == '01' ? '/images/tec/prf/approv_img2.png' :'/images/tec/prf/approv_img1.png'">
                                <span v-if="mberInfo.asgApprStatus == '02'">원소속사 승인 처리중 입니다 <img src="/images/tec/prf/progress.png"></span>
                                <span v-if="mberInfo.asgApprStatus == '04'">원소속사 탈퇴 처리중 입니다 <img src="/images/tec/prf/progress.png"></span>

                                <div v-if="mberInfo.asgApprStatus == '01' || mberInfo.asgApprStatus == '03'" class="approv-name">
                                    <div>
                                        <span style="width: 80px;">원소속사</span>
                                        <span v-if="mberInfo.asgApprStatus == '01'" style="color: #a7a9ac;">원소속사가 없습니다.</span>
                                        <span v-if="mberInfo.asgApprStatus == '03'">{{ mberInfo.corpNm }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="Area">
                                <div class="Item">
                                    <table class="tb">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <span>*</span>
                                                    <label><nbsp/>원소속사구분</label>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <input v-if="mberInfo.asgApprStatus == '01'" type="radio" id="rd06" v-model="asgInfo.mberCorpDiv" value="2"  @change="mberCorpDivCng('Y')">
                                                    <input v-else-if="mberInfo.asgApprStatus == '03'" type="radio" id="rd06" v-model="asgInfo.mberCorpDiv" value="2" onclick ="alert('원소속사 탈퇴 후 변경이 가능합니다.'); return false;">
                                                    <input v-else type="radio" id="rd06" v-model="asgInfo.mberCorpDiv" value="2" onclick ="alert('승인 처리중 일때는 변경이 불가능 합니다.'); return false;">
                                                    <label for="rd06">
                                                        <span class="rd"></span>
                                                        원소속사 있음
                                                        <AutoComplete v-if="disabledChkBox == true" disabled="true" class="ml-3" title="원소속사를 선택하세요">
                                                        </AutoComplete>
                                                        <AutoComplete v-else class="ml-3" type="text" v-model="asgInfo.corpNm" :list="corpList" listText="corpNm" @autoComplete="setCorpInfo" title="원소속사를 선택하세요" :rules="{'required':true}" :disabled="mberInfo.asgApprStatus != '01'">
                                                            <template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
                                                        </AutoComplete>
                                                        
                                                    </label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <input v-if="mberInfo.asgApprStatus == '01'" type="radio" id="rd07" v-model="asgInfo.mberCorpDiv" value="3" @change="mberCorpDivCng('N')">
                                                    <input v-else-if="mberInfo.asgApprStatus == '03'" type="radio" id="rd07" v-model="asgInfo.mberCorpDiv" value="3" onclick ="alert('원소속사 탈퇴 후 변경이 가능합니다.'); return false;">
                                                    <input v-else type="radio" id="rd07" v-model="asgInfo.mberCorpDiv" value="3" onclick ="alert('승인 처리중 일때는 변경이 불가능 합니다.'); return false;">
                                                    <label for="rd07" class="rd">
                                                        <span class="rd"></span>
                                                        원소속사 없음
                                                    </label>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th class="pt-11">
                                                    <span>*</span>
                                                    <label for="txt08"><nbsp/>직 원 구 분</label>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <SelectComp v-if="mberInfo.asgApprStatus == '01'" type="radio" cdId="PRO102" v-model="asgInfo.empDivCd" defValue="01" :disabled="asgInfo.mberCorpDiv == '3'"/>
                                                    <SelectComp v-else-if="mberInfo.asgApprStatus == '03'" type="radio" cdId="PRO102" v-model="asgInfo.empDivCd" defValue="01" onclick ="alert('원소속사 탈퇴 후 변경이 가능합니다.'); return false;"/>
                                                    <SelectComp v-else type="radio" cdId="PRO102" v-model="asgInfo.empDivCd" defValue="01" onclick ="alert('승인 처리중 일때는 변경이 불가능 합니다.'); return false;"/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <!-- 취소/완료 버튼 -->
                        <div class="select_btn mt-8">
                            <!-- <div class="btn_01" @click="btnCancel()">취소</div> -->
                            <div v-if="mberInfo.asgApprStatus == '03'" class="btn_02" style="background-color: #FF681D;" @click="btnSubmit('asgWithdrawal')">원소속사 탈퇴</div>
                            <div v-else-if="mberInfo.asgApprStatus == '02' || mberInfo.asgApprStatus == '04'|| grayBtnFlag == true" class="btn_02 grayBtn">등록하기</div>
                            <div v-else-if="mberInfo.asgApprStatus == '01'" class="btn_03" style="background-color: #17A1FF;" @click="btnSubmit('asgReg')">등록하기</div>
                        </div>

                        <div class="noti-txt">
                            <p>&#8251; 원소속사 승인 관련 유의사항</p>
                            <div class="pl-2">
                                1. 원소속사가 있는 경우, 원소속사 승인을 받아야 합니다. <br />
                                    <nbsp n="4"/><span style="color: red;">*원소속사가 있는 경우, 승인을 받지 않으면 프로젝트에 지원할 수 없음</span><br />
                                2. 원소속사 승인은 해당 원소속사의 상황에 따라 처리기간이 달라질 수 있음을 알려드립니다. <br />
                                3. '원소속사 없음'을 선택하실 경우, 직원구분은 '프리랜서'로 자동설정 됩니다. <br />
                                &#9733; 원소속사 변경을 하실 경우 '소속탈퇴'를 하신 후 변경하실 소속사 승인을 받으셔야 합니다.
                            </div> 
                        </div>
                        
                    </div>


                    <hr class="vertical-hr">

                    <!-- 참여 소속사 승인 요청 -->
                    <div class="corpMng-box company">
                        <div class="tit">프로젝트 참여소속사</div>
                        <div class="corpInfo-box">

                            <div class="approv">
                                <img :src=" mberInfo.joinAsgApprStatus == '01' || mberInfo.joinAsgApprStatus == '03' ? '/images/tec/prf/approv_img2.png' :'/images/tec/prf/approv_img1.png'">
                                <span v-if="mberInfo.joinAsgApprStatus == '02'">참여소속사 승인 처리중 입니다 <img src="/images/tec/prf/progress.png"></span>

                                <div v-else class="approv-name">
                                    <div>
                                        <span style="width: 90px;">참여소속사</span>
                                        <span v-if="mberInfo.joinAsgApprStatus == '01' || mberInfo.joinAsgApprStatus == '03'" style="color: #a7a9ac;">참여소속사가 없습니다.</span>
                                        <span v-if="mberInfo.joinAsgApprStatus == '04'">{{ mberInfo.joinCorpNm }}</span>
                                    </div>
                                </div>
                            </div>

                            <!--참여소속사정보-->
                            <div class="Area">
                                <div class="Item">
                                    <table class="tb">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <span>*</span>
                                                    <label><nbsp/>변경할 참여소속사</label>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <input v-if="mberInfo.joinAsgApprStatus == '02'" type="radio" id="rd08" v-model="joinAsgInfo.mberJoinCorpDiv" value="2" onclick ="alert('승인 처리중 일때는 변경이 불가능 합니다.'); return false;">
                                                    <input v-else type="radio" id="rd08" v-model="joinAsgInfo.mberJoinCorpDiv" value="2" @change="mberJoinCorpDivCng('Y')">
                                                    <label for="rd08">
                                                        <span class="rd"></span>
                                                        참여소속사 있음
                                                        <AutoComplete v-if="disabledJoinChkBox == true" disabled="true" class="ml-3" title="참여소속사를 선택하세요">
                                                        </AutoComplete>
                                                        <AutoComplete v-else class="ml-3" type="text" v-model="joinAsgInfo.joinCorpNm" :list="corpList" listText="corpNm" @autoComplete="setJoinCorpInfo" title="참여소속사를 선택하세요" :rules="{'required':true}" :disabled="mberInfo.joinAsgApprStatus == '02'">
                                                            <template v-slot:autoComplete="autoComplete">{{autoComplete.item.corpNm}} / {{autoComplete.item.bizRegNo}}</template>
                                                        </AutoComplete>
                                                        
                                                    </label>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <input v-if="mberInfo.joinAsgApprStatus == '02'" type="radio" id="rd09" v-model="joinAsgInfo.mberJoinCorpDiv" value="3" onclick ="alert('승인 처리중 일때는 변경이 불가능 합니다.'); return false;">
                                                    <input v-else type="radio" id="rd09" v-model="joinAsgInfo.mberJoinCorpDiv" value="3" @change="mberJoinCorpDivCng('N')">
                                                    
                                                    <label for="rd09" class="rd">
                                                        <span class="rd"></span>
                                                        참여소속사 없음
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>    
                            </div>
                            
                        </div>

                        <!-- 하단 버튼 -->
                        <div class="select_btn mt-8">
                            <!-- <div class="btn_01" @click="btnCancel()">취소</div> -->
                            <div v-if="mberInfo.joinAsgApprStatus == '01'" class="btn_03" style="background-color: #17A1FF;" @click="btnSubmit('joinAsgReg')">등록하기</div>
                            <div v-else-if="mberInfo.joinAsgApprStatus == '02' || grayJoinBtnFlag == true" class="btn_02 grayBtn">등록하기</div>
                            <div v-else class="btn_02" style="background-color: #FF681D;" @click="btnSubmit('joinAsgReg')">수정하기</div>
                        </div>

                        <div class="noti-txt">
                            <p>&#8251; 프로젝트 참여소속사 승인 관련 유의사항</p>
                            <div class="pl-2">
                                1. 프로젝트 참여 소속사가 본인의 인력공급 계약주체로서 계약을 수요기업과 체결하게 됩니다. <br />
                                2. 프로젝트 참여소속사를 변경하실 경우, <br />
                                    <nbsp n="4"/>변경하실 참여소속사를 선택하셔서 승인 받으시면 됩니다. <br />
                                &#9733;  ‘참여소속사 없음’으로 등록하실 경우 별도의 승인절차는 없습니다.     
                            </div>
                        </div>

                    </div>
                    
                    
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import nbsp from '../../../components/base/nbsp.vue';

export default {
  components: { nbsp },

    data() {
        return {
            isMounted: false,
            
            chkGrayBtn : false,
            grayBtnFlag : false,
            disabledChkBox : false,

            chkJoinGrayBtn : false,
            grayJoinBtnFlag : false,
            disabledJoinChkBox : false,

            // asgRegYn : '',

            mberInfo: {
                asgApprStatus : '',

                corpNm : '',
                empDivCd : '',
                aftAsgNm : '',
                aftEmpDivCd : '',

                joinAsgApprStatus : '',

                joinCorpNm : '',
                aftJoinAsgNm : ''

            },

            asgInfo: {
                mberCorpDiv: '2',
                corpSeq : '',
                corpNm : '',
                empDivCd : '',

            },

            joinAsgInfo: {
                mberJoinCorpDiv : '2',
                joinCorpSeq : '',
                joinCorpNm : '',
            },

            corpList : [],
        };
    },
    mounted(){
        // this.asgRegChk();
        this.isMounted = true;
        this.tecAsgInfo();
        this.corpSearch('20');
    },
    methods: {
        // asgRegChk(){
        //     this.$.httpPost('/api/mem/prf/getMberAsgRegInfo', {mberSeq : this.$store.state.userInfo.mberSeq})
        //         .then(res => {
        //             this.asgRegYn = res.data.mberAsgRegInfo.mberAsgRegYn;
        //         })
        //         .catch(err => {
        //             alert(err.response.data.error_description);
        //         });
        // },

        tecAsgInfo(){
            this.$.httpPost('/api/mem/prf/getTecAsgInfo', {mberSeq : this.$store.state.userInfo.mberSeq})
                .then(res => {
                    this.mberInfo = res.data.tecAsgInfo;

                    /* 원소속사 */
                    if(this.mberInfo.asgApprStatus == '01' && this.mberInfo.empDivCd == '02'){ // 소속없음 + 프리랜서
                        this.asgInfo.mberCorpDiv = '3';
                        this.asgInfo.empDivCd = '02';

                        this.chkGrayBtn = true;
                        this.grayBtnFlag = true;

                        this.disabledChkBox = true;

                    }else if(this.mberInfo.asgApprStatus != '01'){ // 소속있음 + (승인대기 또는 승인완료) 
                        this.asgInfo.mberCorpDiv = '2';
                        this.asgInfo.corpNm = this.mberInfo.aftAsgNm;
                        this.asgInfo.corpSeq = this.mberInfo.aftCorpSeq;
                        this.asgInfo.empDivCd = this.mberInfo.aftEmpDivCd;

                        if(this.mberInfo.asgApprStatus == '04') this.asgInfo.empDivCd = this.mberInfo.empDivCd;

                        // 추가개발 전 소속사 승인없이 가입된 기술인재
                        if(this.mberInfo.mberAsgHistorySeq == null || this.mberInfo.mberAsgHistorySeq == ''){
                            this.asgInfo.corpNm = this.mberInfo.corpNm;
                            this.asgInfo.corpSeq = this.mberInfo.corpSeq;
                            this.asgInfo.empDivCd = this.mberInfo.empDivCd;
                        }
                    }

                    /* 참여소속사 */
                    if(this.mberInfo.joinAsgApprStatus == '02'){ // 승인대기
                        this.joinAsgInfo.joinCorpNm = this.mberInfo.aftJoinAsgNm;
                        this.joinAsgInfo.joinCorpSeq = this.mberInfo.aftJoinCorpSeq;
                    }
                    if(this.mberInfo.joinAsgApprStatus == '03'){ // 참여소속없음 + 승인완료
                        this.joinAsgInfo.mberJoinCorpDiv = '3';
                        this.chkJoinGrayBtn = true;
                        this.grayJoinBtnFlag = true;
                        this.disabledJoinChkBox = true;
                        
                    }
                    if(this.mberInfo.joinAsgApprStatus == '04'){ // 참여소속있음 + 승인완료
                        this.joinAsgInfo.mberJoinCorpDiv = '2';
                        this.joinAsgInfo.joinCorpNm = this.mberInfo.aftJoinAsgNm;
                        this.joinAsgInfo.joinCorpSeq = this.mberInfo.aftJoinCorpSeq;
                    }

                }).catch(err => {
                    alert(err.response.data.error_description);
            });
        },

        corpSearch(div) {
            var param = {
                corpDivCd : div,
                //pageType : 'cham',
            }
			this.$.httpPost('/api/mem/prf/getCorpList', param)
                .then(res => {
                    this.corpList = res.data.corpList;
                }).catch(err => {
                    alert(err.response.data.error_description);
            });
		},

        setCorpInfo(corpInfo) {
			if(corpInfo) {
				this.asgInfo.corpSeq = corpInfo.corpSeq;
				this.asgInfo.corpNm = corpInfo.corpNm;
			}else {
				this.asgInfo.corpSeq = '';
				this.asgInfo.corpNm = '';
			}
		},

        setJoinCorpInfo(corpInfo) {
			if(corpInfo) {
				this.joinAsgInfo.joinCorpSeq = corpInfo.corpSeq;
				this.joinAsgInfo.joinCorpNm = corpInfo.corpNm;
			}else {
				this.joinAsgInfo.joinCorpSeq = '';
				this.joinAsgInfo.joinCorpNm = '';
			}
		},

        // 소속사 구분 변경
		mberCorpDivCng(div) {
            if(div == 'N'){
                this.asgInfo.corpSeq = '';
                this.asgInfo.corpNm = '';
                this.asgInfo.bizRegNo = '';
                this.asgInfo.empDivCd = '02';

                this.disabledChkBox = true;
            }else{
                this.disabledChkBox = false;
            }

            if(this.chkGrayBtn == true){
                if(div == 'Y') this.grayBtnFlag = false;  
                else this.grayBtnFlag = true;
            }
		},

        // 참여소속사 구분 변경
		mberJoinCorpDivCng(div) {
            if(div == 'N'){
                this.joinAsgInfo.joinCorpSeq = '';
                this.joinAsgInfo.joinCorpNm = '';

                this.disabledJoinChkBox = true;
            }else{
                this.disabledJoinChkBox = false;
            }

            if(this.chkJoinGrayBtn == true){
                if(div == 'Y') this.grayJoinBtnFlag = false;  
                else this.grayJoinBtnFlag = true;
            }
		},

        btnSubmit(div){
            if(this.mberInfo.apprChkYn != 'Y'){
                alert("미승인된 승인 요청 건이 있습니다. 승인 완료 후 요청이 가능합니다!");

            }else{
                var dataChk = true;
                var param = {
                    mberSeq : this.$store.state.userInfo.mberSeq,
                }

                if(div == 'asgReg'){
                    if(this.asgInfo.mberCorpDiv == '2' && (this.asgInfo.corpSeq == null || this.asgInfo.corpSeq == '')) {
                        dataChk = false;
                        alert("소속사를 선택해주세요!");
                    }else{
                        param.classDivCd = '02';
                        param.corpSeq = this.asgInfo.corpSeq;
                        param.corpNm = this.asgInfo.corpNm;
                        param.empDivCd = this.asgInfo.empDivCd;
                    }

                }else if(div == 'asgWithdrawal'){
                    param.classDivCd = '04';
                    param.corpSeq = this.mberInfo.corpSeq;
                    param.corpNm = this.mberInfo.corpNm;
                    param.empDivCd = this.mberInfo.empDivCd;

                }else if(div =='joinAsgReg'){
                    if(this.joinAsgInfo.mberJoinCorpDiv == '2' && (this.joinAsgInfo.joinCorpSeq == null || this.joinAsgInfo.joinCorpSeq == '')) {
                        dataChk = false;
                        alert("참여 소속사를 선택해주세요!");

                    }else if(this.mberInfo.projectJoinCorpSeq == this.joinAsgInfo.joinCorpSeq){
                        dataChk = false;
                        alert("현재 참여소속사와 변경할 참여소속사가 같습니다.");

                    }else{
                        param.classDivCd = '03';

                        if(this.joinAsgInfo.mberJoinCorpDiv == '3' && !(this.mberInfo.projectJoinCorpSeq == null || this.mberInfo.projectJoinCorpSeq == '')){
                            param.classDivCd = '05';
                        }
                        
                        param.corpSeq = this.joinAsgInfo.joinCorpSeq;
                        param.corpNm = this.joinAsgInfo.joinCorpNm;
                        param.mberJoinCorpDiv = this.joinAsgInfo.mberJoinCorpDiv;
                    }
                }

                if(dataChk == true) { this.$.popup('/tec/prf/PRF104P01', param); }
            }
          
        },
       
        btnCancel(){
            this.$router.go(-1);
        },

        // 페이지 이동
		pageMove(div) {
            if(div == 'res') {
                this.$router.push('PRF101M01')
            } else if(div == 'ncs') { 
                this.$router.push('PRF102M02');
            } else {
				this.$router.go(-1);
			}    
		},
    },
};
</script>